// @flow
import React from 'react'
import type { Node } from 'react'
import '../../styles/main.scss'

type Props = {
  children: Node
}

export const Layout = ({ children }: Props) => (
  <>
    <main className="page">
      {children}
    </main>
  </>
)
