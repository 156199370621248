// @flow
import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
  title: string,
  description?: string,
  lang?: string,
  meta?: Array<{ [string]: string }>,
}

const defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
}

export const SEO = ({
  description, lang, meta, title,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        // Add noindex and nofollow to build unless it is production
        (
          process.env.GATSBY_ACTIVE_ENV !== 'production'
            ? {
              name: 'robots',
              content: 'noindex, nofollow',
            }
            : {}
        ),
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = defaultProps
